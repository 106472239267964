import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { Provider } from "react-redux";
import store from "./redux/store/index";

import "./i18n";

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById("root")
);

// if ("serviceWorker" in navigator) {
// 	window.addEventListener("load", () => {
// 		navigator.serviceWorker
// 			.register("/service-worker.js")
// 			.then((registration) => {
// 				console.log("Service Worker registered with scope:", registration.scope);
// 			})
// 			.catch((error) => {
// 				console.error("Service Worker registration failed:", error);
// 			});
// 	});
// }
